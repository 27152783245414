import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Submission",
      component: () => import("./views/Submission"),
      meta: {
        public: true,
      },
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("./views/Login"),
      meta: {
        public: true,
      },
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: () => import("./views/Dashboard"),
      meta: {
        private: true,
      },
    },
  ],
});
