import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueMaterial from "vue-material";
import "./config";
// Styles
import "vue-material/dist/vue-material.min.css";
import "./styles/main.scss";

Vue.config.productionTip = false;

// Remove badInput error
// Reason: Validation has a bug causing a false negative
Vue.config.errorHandler = (err) => {
  if (process.env.NODE_ENV !== "production") {
    // Show any error except this one
    if (
      err.message !== "Cannot read properties of undefined (reading 'badInput')"
    ) {
      console.error(err);
    }
  }
};

Vue.use(VueMaterial);

// Config of material ui
Vue.material.locale.dateFormat = "dd.MM.yyyy";
Vue.material.locale.firstDayOfAWeek = 1;
Vue.material.locale.cancel = "Abbrechen";
Vue.material.locale.ok = "Ok";
Vue.material.locale.days = [
  "Sonntag",
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
];
Vue.material.locale.shortDays = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
Vue.material.locale.shorterDays = ["S", "M", "D", "M", "D", "F", "S"];
Vue.material.locale.months = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];
Vue.material.locale.shortMonths = [
  "Jan",
  "Feb",
  "Mär",
  "Apr",
  "Mai",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Dez",
];
Vue.material.locale.shorterMonths = [
  "J",
  "F",
  "M",
  "A",
  "M",
  "J",
  "J",
  "A",
  "S",
  "O",
  "N",
  "D",
];

// Navigation guard
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.private)) {
    if (!localStorage.getItem("userid")) next({ name: "Login" });
    else next();
  } else if (to.matched.some((record) => record.meta.public)) {
    if (localStorage.getItem("userid")) next({ name: "Dashboard" });
    else next();
  } else {
    next();
  }
});

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
